import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
      loadingCount:0,
      isLoading:false,
      showError:false,
      errorMessage:'',
  },
  mutations: {
      clearError(state){
          state.showError=false;
      },
      setError(state){
          state.showError=true;
      },
      setLoading(state){
          state.isLoading=true;
          state.loadingCount++;
      },
      clearLoading(state){
          state.loadingCount--;
          if(state.loadingCount===0){
              state.isLoading=false;
          }
      },
  },
  actions: {
  },
  modules: {
  }

})
